<template>
  <div class="testimonies template-1" ref="container">
    <header class="page-header container mx-sm">
      <h1>{{ translations.tcTestimony }}</h1>
      <BannerAid :bannerLink="`testimony|` + userSelectedArticleKey" />
    </header>
    <div class="page-body container mx-sm">
      <div v-if="!!alpha && !!alpha.title" class="testimony">
        <h2 class="title-1">{{ alpha.title }}</h2>
        <h4 class="title-2">
          {{ returnFormattedDate(alpha.testimony_date) }}
          <span v-if="alpha.testimony_date !== null">|</span>
          {{ alpha.testimony_type }} | {{ alpha.country }}
        </h4>
        <p v-html="adjustMessage(alpha.article)"></p>
        <div class="inline-flex">
          <button class="btn btn-primary mr-4" @click="handleSeeAllClick">{{ translations.tcSeeAll }}</button>
          <button class="btn btn-secondary" @click="handleSubmitTestimonyClick">{{ translations.tcSubmit }}</button>
          <a class="btn btn-tertiary ml-4" style="background-color: #ffc107" download
            @click.prevent="downloadReport(testimonyUrl, 'Testimony', 'pdf', alpha)">{{
              translations.tcSaveAsPDF
            }}</a>
        </div>
      </div>
    </div>
    <div class="page-footer"></div>
    <div class="page-extra">
      <div class="container bg-primary more-testimonials">
        <h2 class="text-white">{{ translations.tcMoreTestimonials }}</h2>
        <div class="d-flex">
          <div class="bg-white" v-for="(item, indx) in [beta, gamma, delta]" :key="indx">
            <h3>{{ item.title }}</h3>
            <div class="d-flex meta-date">
              <div>{{ returnFormattedDate(item.testimony_date) }}</div>
            </div>
            <p>{{ charLimit(item.description, 120) }}</p>
            <a href="#" @click.prevent="handleReadMoreClick(item.art_key)" class="btn btn-secondary">
              {{ translations.tcReadMore }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BannerAid from '@/components/BannerAid.vue'
import { messagePresentationMixin } from '@/mixins/messagePresentationMixin'
import { reportDownloadMixin } from '@/mixins/reportDownloadMixin'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'testimony',
  mixins: [reportDownloadMixin, messagePresentationMixin, translationMixin],
  data() {
    return {
      betaIndex: null,
      deltaIndex: null,
      empty_testimony: {
        title: '',
        article: '',
        country: '',
        testimony_date: '',
      },
      gammaIndex: null,
      translations: {},
    }
  },
  methods: {
    ...mapActions({
      getSpecificTestimony: 'testimony/getSpecificTestimony',
      getTestimonies: 'testimony/getTestimonies',
      getTestimonyUrl: 'testimony/getTestimonyUrl',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedArticleKey: 'user/setSelectedArticleKey',
    }),
    charLimit(text, limit) {
      return !text ? '' : text.length > limit ? text.substring(0, limit) + '...' : text
    },
    async handleSeeAllClick() {
      this.$router.push('/get-connected/stay-informed/testimonies')
    },
    async handleSubmitTestimonyClick() {
      this.$router.push('/get-connected/stay-informed/submit-testimony')
    },
    async handleReadMoreClick(evt) {
      await this.setSelectedArticleKey(evt)
      await this.handleSpecificTestimonyRequest()
      this.$refs.container.scrollIntoView({ behavior: 'smooth' })
    },
    async handleSpecificTestimonyRequest() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([await this.getSpecificTestimony(this.userSelectedArticleKey)])
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async translateAlpha() {
      if (this.alpha && !!this.alpha.testimony_type) {
        let typeLookup = !!this.alpha.testimony_type ? this.alpha.testimony_type : ''
        typeLookup = this.convertValForTranslation(typeLookup)
        this.alpha.testimony_type = this.translations.hasOwnProperty(typeLookup)
          ? this.translations[typeLookup]
          : this.alpha.testimony_type
      }
    }
  },
  computed: {
    ...mapGetters({
      prefCulture: 'user/userPreferredCulture',
      testimonies: 'testimony/testimonies',
      testimonySummary: 'testimony/testimonySummary',
      testimonyUrl: 'testimony/testimonyUrl',
      userSelectedArticleKey: 'user/userSelectedArticleKey',
    }),
    alpha() {
      let alphaIndex = -1
      let alphaArticle =
        this.testimonies.filter((tst, index) => {
          let result = tst.art_key === this.userSelectedArticleKey
          if (result) {
            alphaIndex = index
          }
          return result
        })[0] || this.empty_story
      if (alphaIndex > -1 && this.testimonies.length > 4) {
        let availableIndexes = [...Array(this.testimonies.length).keys()]
        availableIndexes.splice(alphaIndex, 1)
        this.betaIndex = availableIndexes[Math.floor(Math.random() * availableIndexes.length)]
        availableIndexes.splice(availableIndexes.indexOf(this.betaIndex), 1)
        this.gammaIndex = availableIndexes[Math.floor(Math.random() * availableIndexes.length)]
        availableIndexes.splice(availableIndexes.indexOf(this.gammaIndex), 1)
        this.deltaIndex = availableIndexes[Math.floor(Math.random() * availableIndexes.length)]
      } else {
        this.betaIndex = 1
        this.gammaIndex = 2
        this.deltaIndex = 3
      }
      return alphaArticle
    },
    beta() {
      return this.testimonies[this.betaIndex] || this.empty_testimony
    },
    gamma() {
      return this.testimonies[this.gammaIndex] || this.empty_testimony
    },
    delta() {
      return this.testimonies[this.deltaIndex] || this.empty_testimony
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getTestimonies({ rows: 6, selected_key: this.userSelectedArticleKey }),
        this.getTestimonyUrl(),
        this.getViewTranslations(),
      ]).then(() => {
        this.translateAlpha()
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  components: {
    BannerAid: BannerAid,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';

.testimony {
  .title-1 {
    margin-bottom: 0;
    color: #000;
    font-family: $DIN;
    font-size: 40px;
    letter-spacing: 0.03em;
    line-height: 44px;
    text-transform: uppercase;
  }

  .title-2 {
    margin-bottom: 8px;
    color: $teal-700;
    font-size: 30px;
    letter-spacing: 0.03em;
    line-height: 44px;
    text-transform: none;
  }

  p {
    margin-bottom: 36px;
    font-size: 18px;
    font-family: $lora;
    letter-spacing: 0.03em;
    line-height: 36px;
  }
}

.page-extra {
  @include breakpoint(sm) {
    padding: 0 15px;
  }

  .more-testimonials {
    padding: 50px 60px 60px;

    @include breakpoint(sm) {
      padding: 30px 68px 68px;
    }

    >.d-flex {
      @include breakpoint(sm) {
        flex-wrap: wrap;
      }

      >div {
        margin-right: 35px;
        padding: 21px 33px 30px;

        @include breakpoint(sm) {
          width: 100%;
          margin-bottom: 24px;
          margin-right: 0;
          padding: 34px 50px;
        }

        &:last-of-type {
          margin-right: 0;

          @include breakpoint(sm) {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  h2 {
    margin-bottom: 30px;
    font-family: $DIN;
    font-size: 40px;
    letter-spacing: 1.33px;
    line-height: 44px;
  }

  h3 {
    margin-bottom: 2px;
    color: #000;
    font-family: $DIN;
    font-size: 26px;
    letter-spacing: 0.78px;
    line-height: 44px;
  }

  .meta-date {
    margin-bottom: 11px;
    font-size: 12px;
    letter-spacing: 0.6px;
    line-height: 36px;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 43px;
    font-family: $lora;
    font-size: 16px;
    letter-spacing: 0.64px;
    line-height: 24px;
  }
}
</style>
